import React, { useEffect } from "react";
import { HashRouter, Route, Switch } from "react-router-dom";
import "./scss/style.scss";
import { useStore } from "react-redux";

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
);

// Containers
const TheLayout = React.lazy(() => import("./containers/TheLayout"));

// Pages
const Login = React.lazy(() => import("./views/pages/login/Login"));
const RegisterLogin = React.lazy(() =>
  import("./views/pages/login/CompleteRegistration")
);
const CompletePayment = React.lazy(() =>
import("./views/pages/login/CompletePayment")
);
const Register = React.lazy(() => import("./views/pages/register/Register"));
const Page404 = React.lazy(() => import("./views/pages/page404/Page404"));
const Page500 = React.lazy(() => import("./views/pages/page500/Page500"));
const ForgotPassword = React.lazy(() =>
  import("./views/pages/login/forgotPassword")
);

function App (props) {
  // constructor(props) {
  //   super(props);
  //   this.state = {
  //     isLoggedIn: false
  //   };
  // }
  const store = useStore();

  useEffect(()=>{
    let x = store.getState().host;
    if (typeof(x) === 'object'){
      window.location.reload()
    }
  })

  // render() {
    return (
      <HashRouter>
        <React.Suspense fallback={loading}>
          <Switch>
            <Route
              exact
              path="/login"
              name="EAMS Login"
              render={props => <Login {...props} />}
            />
            <Route
              exact
              path="/complete-subscription"
              name="EAMS Subscription"
              render={props => <CompletePayment {...props} />}
            />
            <Route
              exact
              path="/complete-registration"
              name="EAMS Login"
              render={props => <RegisterLogin {...props} />}
            />
            <Route
              exact
              path="/register"
              name="EAMS Register Page"
              render={props => <Register {...props} />}
            />
            <Route
              exact
              path="/404"
              name="Page 404"
              render={props => <Page404 {...props} />}
            />
            <Route
              exact
              path="/500"
              name="Page 500"
              render={props => <Page500 {...props} />}
            />
            <Route
              exact
              path="/forgot-password"
              name="Forgot Password"
              render={props => <ForgotPassword {...props} />}
            />
            <Route
              path="/"
              name="Home"
              render={props => <TheLayout {...props} />}
            />
          </Switch>
        </React.Suspense>
      </HashRouter>
    );
  // }
}

export default App;
