import { createStore } from 'redux'
// import logo from "../../assets/images/EAMS_coloured_white.jpg";
import logo from './assets/images/EAMS_coloured_white.jpg'

const initialState = {
  sidebarShow: 'responsive',
  host: 'https://api.eams.mobi/api/v1',
  media_host: 'https://api.eams.mobi',
  // host: 'http://localhost:8000/api/v1',
  // media_host: 'http://localhost:8000',
  successColor: 'green',
  failureColor: 'red',
  infoColor: 'blue',
  roles: ['Client Admin', 'Finance Manager', 'Mechanic', 'Driver / Operator','Procurement Officer'],

  token: sessionStorage.getItem('token'),
  isLoggedIn: sessionStorage.getItem('isLoggedIn'),
  super_admin: sessionStorage.getItem('super_admin'),
  profile: sessionStorage.getItem('profile'),
  user_roles: sessionStorage.getItem('roles'),

  //default logo for EAMS
  defaultLogo: logo
}

const changeState = (state = initialState, { type, ...rest }) => {
  switch (type) {
    case 'set':
      return {...state, ...rest }
    default:
      return state
  }
}

const store = createStore(changeState)
export default store